body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: rgb(240, 240, 232) !important;
}

* {
  margin: 0;
  padding: 0;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.form_login {
 display: flex;
 justify-content: center;
 align-items: center;
 flex-direction: column;
 width: 50%; 
 margin-left: 20%;
}

#page-wrap {
  text-align: center;
  overflow: auto;
}

.bm-item {
  display: inline-block;
  text-decoration: none;
  margin-bottom: 10px;
  color: #d1d1d1;
  transition: color 0.2s;
}

.bm-item:hover {
  color: white;
}

.bm-burger-button {
  position: fixed;
  width: 36px;
  height: 30px;
  left: 36px;
  top: 36px;
}

.bm-burger-bars {
  background: #373a47;
}

.bm-cross-button {
  height: 24px;
  width: 24px;
}

.bm-cross {
  background: #bdc3c7;
}

.bm-menu {
  background: #373a47;
  padding: 2.5em 1.5em 0;
  font-size: 1.15em;
}

.bm-morph-shape {
  fill: #373a47;
}

.bm-item-list {
  color: #b8b7ad;
}

.bm-overlay {
  background: rgba(0, 0, 0, 0.3);
}

.pagecontainer{
  background-color: rgb(243, 242, 242);
  width: 100%;
  height: 100%;
  margin-top: -3%;
  padding: 3%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;  
}

.dropdown {
  float: left;
  overflow: hidden;
}

.dropdown .dropbtn {
  font-size: 16px;  
  border: none;
  outline: none;
  color: white;
  padding: 14px 16px;
  background-color: inherit;
  font-family: inherit;
  margin: 0;
}


.dropdown-content {
  display: none;
  position: absolute;
  background-color: #f9f9f9;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  z-index: 1;
}

.dropdown-content a {
  float: none;
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
  text-align: left;
}

.dropdown-content a:hover {
  background-color: #ddd;
}

.dropdown:hover .dropdown-content {
  display: block;
}

/*   .action_btn {
    width: 200px;
    margin: 0 auto;
    display: inline;
  } */

  .div-container{
    border: 1px solid black;
    padding: 10px;
    margin: 10px;
    border-radius: 5px;
   }


  @media screen and (max-width: 540px) {
    .button-text-exp {
        display: EXP;
    }
    .button-text-ga {
      display: GA;
    }
    .button-text-noga {
      display: NGA;
    }
    .spacebtn {
      margin-top: 2%;
      margin-bottom: 2%;
    }
}

@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.loading-spinner {
  width: 50px;
  height: 50px;
  border: 5px solid #f3f3f3; /* Light grey */
  border-top: 10px solid #383636; /* Black */
  border-radius: 50%;
  animation: spinner 1.5s linear infinite;
  margin-left: auto;
  margin-right: auto;
  margin-top: 10%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.spinner {
  animation-name: spin;
  animation-duration: 500ms;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}

.pluswrap {
  position: fixed;
  width: 100%;
  height:100%;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
}

.plus {
  display: flex;
  margin: 0 auto;
}

.login-container{
  background-color: rgb(243, 242, 242);
  width: 50%;
  height: 50%;
  margin-left: 25%;
  margin-top: 3%;
  margin-bottom: 5%;
  padding: 3%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;  
}

.dialogForm{
  display: flex;
  justify-content: center;
  align-items: center;
}

/* .makeStyles-paper-1{
  width: 450px !important
} */

/* .MuiInputBase-input{
  width: 300px !important;
} */


/* .MuiOutlinedInput-input{
  width: 15rem !important;
  margin-right: 9.5rem !important;
  overflow:visible!important;
}  */

#header {
  z-index: 200;
  height: 60px;
  position: absolute;
  width: 100%;
  margin-right: 0;
}

#header .logo {
  color: #1d212a;
  font-size: 1.188em;
  text-transform: uppercase;
  padding: 17px 0 0 10px;
  display: inline-block;
}
#header .logo {
  color: #1d212a;
  font-size: 1.188em;
  text-transform: uppercase;
  padding: 17px 0 0 10px;
  display: inline-block;
}
#header .logo img {
  margin-right: 6px;
  margin-top: -4px;
}
#header .logo span {
  font-weight: 700;
}

/* ==========================================================================
   Login, Sign Up, Loacked Screen
   ========================================================================== */
   #login-wrapper {
    margin: 100px auto;
  }
  #login-wrapper .logo {
    color: #1d212a;
    font-size: 2em;
    text-transform: uppercase;
    padding: 15px 0 0 15px;
    display: inline-block;
  }
  #login-wrapper .logo span {
    font-weight: 700;
  }
  #login-wrapper .logo img {
    margin-top: -5px;
  }
  #login-wrapper .brand {
    text-align: center;
    padding: 20px 0;
  }
  #login-wrapper .form-group i {
    position: absolute;
    left: 27px;
    top: 11px;
  }
  #login-wrapper #email,
  #login-wrapper #password {
    padding-left: 32px;
  }
  #login-wrapper #locked-screen .profile-pic {
    margin: 15px 0;
  }


  /*PANEL */

  .panel {
    border: none;
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    -ms-border-radius: 0;
    -o-border-radius: 0;
    border-radius: 0;
  }
  .panel > .panel-heading {
    font-weight: 400;
    text-transform: uppercase;
    padding: 14px 10px;
  }
  .panel-heading > .panel-title {
    height: auto;
    font-size: 0.813em;
  }
  .panel > .panel-footer {
    border-color: #ecedf0;
  }
  .modal-footer .btn + .btn {
    margin-bottom: 5px;
  }
  .panel .actions {
    position: absolute;
    right: 30px;
    top: 8px;
  }
  .panel .actions i {
    font-size: 0.875em;
    margin: 0 3px;
  }
  .panel .actions i:hover {
    cursor: pointer;
  }
  .panel > .panel-footer {
    font-weight: 400;
    text-transform: uppercase;
    padding: 15px;
  }
  .panel .panel-body .h1 {
    float: none;
  }
  .panel-default > .panel-heading {
    border-color: #eff2f7;
    background: #fff;
    color: #767676;
  }
  .panel-default .actions i {
    color: #909aa0;
    margin: 0 3px;
  }
  .panel-default .actions i:hover {
    cursor: pointer;
    color: #767676;
  }
  .panel-default > .panel-footer {
    border-color: #eff2f7;
    background: #fff;
    color: #767676;
  }
  .panel-primary > .panel-heading {
    color: #fff;
    background-color: #556b8d;
    border-color: #556b8d;
  }
  .panel-primary {
    border-color: #1abc9c;
  }
  .panel-primary > .panel-heading a,
  .panel-primary > .panel-heading a:hover {
    color: #ffffff;
  }
  .panel-solid-default > .panel-body,
  .panel-solid-default > .panel-footer,
  .panel-solid-default > .panel-heading {
    background: #909aa0;
    border: none;
    color: #ffffff;
  }
  .panel-solid-primary > .panel-body,
  .panel-solid-primary > .panel-footer,
  .panel-solid-primary > .panel-heading {
    background: #556b8d;
    color: #fff;
    border: none;
  }
  .panel-solid-success > .panel-body,
  .panel-solid-success > .panel-footer,
  .panel-solid-success > .panel-heading {
    background: #27b6af;
    color: #fff;
    border: none;
  }
  .panel-solid-warning > .panel-body,
  .panel-solid-warning > .panel-footer,
  .panel-solid-warning > .panel-heading {
    background: #edce8c;
    color: #fff;
    border: none;
  }
  .panel-solid-info > .panel-body,
  .panel-solid-info > .panel-footer,
  .panel-solid-info > .panel-heading {
    background: #1f7bb6;
    color: #fff;
    border: none;
  }
  .panel-solid-danger > .panel-body,
  .panel-solid-danger > .panel-footer,
  .panel-solid-danger > .panel-heading {
    background: #e25d5d;
    color: #fff;
    border: none;
  }
  .panel-gradient-default > .panel-body {
    background: #909aa0;
    /* Old browsers */
    background: -moz-linear-gradient(-45deg, #909aa0 25%, #a4aaad 100%);
    /* FF3.6+ */
    background: -webkit-gradient(linear, left top, right bottom, color-stop(25%, #909aa0), color-stop(100%, #a4aaad));
    /* Chrome,Safari4+ */
    background: -webkit-linear-gradient(-45deg, #909aa0 25%, #a4aaad 100%);
    /* Chrome10+,Safari5.1+ */
    background: -o-linear-gradient(-45deg, #909aa0 25%, #a4aaad 100%);
    /* Opera 11.10+ */
    background: -ms-linear-gradient(-45deg, #909aa0 25%, #a4aaad 100%);
    /* IE10+ */
    background: linear-gradient(135deg, #909aa0 25%, #a4aaad 100%);
    /* W3C */
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='@defaultColor', endColorstr='#a4aaad', GradientType=1);
    /* IE6-9 fallback on horizontal gradient */
    color: #fff;
    border: none;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    -ms-border-radius: 3px;
    -o-border-radius: 3px;
    border-radius: 3px;
  }
  .panel-gradient-primary > .panel-body {
    background: #1abc9c;
    /* Old browsers */
    background: -moz-linear-gradient(-45deg, #1abc9c 25%, #87d5ab 100%);
    /* FF3.6+ */
    background: -webkit-gradient(linear, left top, right bottom, color-stop(25%, #1abc9c), color-stop(100%, #87d5ab));
    /* Chrome,Safari4+ */
    background: -webkit-linear-gradient(-45deg, #1abc9c 25%, #87d5ab 100%);
    /* Chrome10+,Safari5.1+ */
    background: -o-linear-gradient(-45deg, #1abc9c 25%, #87d5ab 100%);
    /* Opera 11.10+ */
    background: -ms-linear-gradient(-45deg, #1abc9c 25%, #87d5ab 100%);
    /* IE10+ */
    background: linear-gradient(135deg, #1abc9c 25%, #87d5ab 100%);
    /* W3C */
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#1abc9c', endColorstr='#87d5ab', GradientType=1);
    /* IE6-9 fallback on horizontal gradient */
    color: #fff;
    border: none;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    -ms-border-radius: 3px;
    -o-border-radius: 3px;
    border-radius: 3px;
  }
  .panel-gradient-success > .panel-body {
    background: #2dcc70;
    /* Old browsers */
    background: -moz-linear-gradient(-45deg, #2dcc70 25%, #27ad5f 100%);
    /* FF3.6+ */
    background: -webkit-gradient(linear, left top, right bottom, color-stop(25%, #2dcc70), color-stop(100%, #27ad5f));
    /* Chrome,Safari4+ */
    background: -webkit-linear-gradient(-45deg, #2dcc70 25%, #27ad5f 100%);
    /* Chrome10+,Safari5.1+ */
    background: -o-linear-gradient(-45deg, #2dcc70 25%, #27ad5f 100%);
    /* Opera 11.10+ */
    background: -ms-linear-gradient(-45deg, #2dcc70 25%, #27ad5f 100%);
    /* IE10+ */
    background: linear-gradient(135deg, #2dcc70 25%, #27ad5f 100%);
    /* W3C */
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#2dcc70', endColorstr='#27ad5f', GradientType=1);
    /* IE6-9 fallback on horizontal gradient */
    color: #fff;
    border: none;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    -ms-border-radius: 3px;
    -o-border-radius: 3px;
    border-radius: 3px;
  }
  .panel-gradient-warning > .panel-body {
    background: #f1c40f;
    /* Old browsers */
    background: -moz-linear-gradient(-45deg, #f1c40f 25%, #e0af0f 100%);
    /* FF3.6+ */
    background: -webkit-gradient(linear, left top, right bottom, color-stop(25%, #f1c40f), color-stop(100%, #e0af0f));
    /* Chrome,Safari4+ */
    background: -webkit-linear-gradient(-45deg, #f1c40f 25%, #e0af0f 100%);
    /* Chrome10+,Safari5.1+ */
    background: -o-linear-gradient(-45deg, #f1c40f 25%, #e0af0f 100%);
    /* Opera 11.10+ */
    background: -ms-linear-gradient(-45deg, #f1c40f 25%, #e0af0f 100%);
    /* IE10+ */
    background: linear-gradient(135deg, #f1c40f 25%, #e0af0f 100%);
    /* W3C */
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#f1c40f', endColorstr='#e0af0f', GradientType=1);
    /* IE6-9 fallback on horizontal gradient */
    color: #fff;
    border: none;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    -ms-border-radius: 3px;
    -o-border-radius: 3px;
    border-radius: 3px;
  }
  .panel-gradient-info > .panel-body {
    background: #3598db;
    /* Old browsers */
    background: -moz-linear-gradient(-45deg, #3598db 25%, #0e7bce 100%);
    /* Chrome,Safari4+ */
    background: -webkit-linear-gradient(-45deg, #3598db 25%, #0e7bce 100%);
    /* Chrome10+,Safari5.1+ */
    background: -o-linear-gradient(-45deg, #3598db 25%, #0e7bce 100%);
    /* Opera 11.10+ */
    background: -ms-linear-gradient(-45deg, #3598db 25%, #0e7bce 100%);
    /* IE10+ */
    background: linear-gradient(135deg, #3598db 25%, #0e7bce 100%);
    /* W3C */
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#3598db', endColorstr='#0e7bce', GradientType=1);
    /* IE6-9 fallback on horizontal gradient */
    color: #fff;
    border: none;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    -ms-border-radius: 3px;
    -o-border-radius: 3px;
    border-radius: 3px;
  }
  .panel-gradient-info > .panel-body a:link,
  .panel-gradient-info > .panel-body a:visited {
    color: #fff;
    text-decoration: none;
  }
  .panel-gradient-info > .panel-body a:hover,
  .panel-gradient-info > .panel-body a:active {
    color: #fff;
    text-decoration: underline;
  }
  .panel-gradient-danger > .panel-body {
    background: #e84c3d;
    /* Old browsers */
    background: -moz-linear-gradient(-45deg, #e84c3d 25%, #d34639 100%);
    /* Chrome,Safari4+ */
    background: -webkit-linear-gradient(-45deg, #e84c3d 25%, #d34639 100%);
    /* Chrome10+,Safari5.1+ */
    background: -o-linear-gradient(-45deg, #e84c3d 25%, #d34639 100%);
    /* Opera 11.10+ */
    background: -ms-linear-gradient(-45deg, #e84c3d 25%, #d34639 100%);
    /* IE10+ */
    background: linear-gradient(135deg, #e84c3d 25%, #d34639 100%);
    /* W3C */
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#e84c3d', endColorstr='#d34639', GradientType=1);
    /* IE6-9 fallback on horizontal gradient */
    color: #fff;
    border: none;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    -ms-border-radius: 3px;
    -o-border-radius: 3px;
    border-radius: 3px;
  }

  /*Nice dropdown */
  .nice-select {
    -webkit-tap-highlight-color: transparent;
    background-color: rgba(255,255,255,0.5);
    border-radius: 5px;
    border: solid 0.4px #010000;
    box-sizing: border-box;
    clear: both;
    cursor: pointer;
    display: block;
    float: left;
    font-family: inherit;
    font-size: 14px;
    font-weight: normal;
    height: 42px;
    line-height: 40px;
    outline: none;
    padding-left: 18px;
    padding-right: 30px;
    position: relative;
    text-align: left !important;
    -webkit-transition: all 0.2s ease-in-out;
    transition: all 0.2s ease-in-out;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
    white-space: nowrap;
    width: auto; }
    .nice-select:hover {
      border-color: #dbdbdb; }
    .nice-select:active, .nice-select.open, .nice-select:focus {
      border-color: #999; }
    .nice-select:after {
      border-bottom: 2px solid #999;
      border-right: 2px solid #999;
      content: '';
      display: block;
      height: 5px;
      margin-top: -4px;
      pointer-events: none;
      position: absolute;
      right: 12px;
      top: 50%;
      -webkit-transform-origin: 66% 66%;
          -ms-transform-origin: 66% 66%;
              transform-origin: 66% 66%;
      -webkit-transform: rotate(45deg);
          -ms-transform: rotate(45deg);
              transform: rotate(45deg);
      -webkit-transition: all 0.15s ease-in-out;
      transition: all 0.15s ease-in-out;
      width: 5px; }
    .nice-select.open:after {
      -webkit-transform: rotate(-135deg);
          -ms-transform: rotate(-135deg);
              transform: rotate(-135deg); }
    .nice-select.open .list {
      opacity: 1;
      pointer-events: auto;
      -webkit-transform: scale(1) translateY(0);
          -ms-transform: scale(1) translateY(0);
              transform: scale(1) translateY(0); }
    .nice-select.disabled {
      border-color: #ededed;
      color: #999;
      pointer-events: none; }
      .nice-select.disabled:after {
        border-color: #cccccc; }
    .nice-select.wide {
      width: 100%; }
      .nice-select.wide .list {
        left: 0 !important;
        right: 0 !important; }
    .nice-select.right {
      float: right; }
      .nice-select.right .list {
        left: auto;
        right: 0; }
    .nice-select.small {
      font-size: 12px;
      height: 36px;
      line-height: 34px; }
      .nice-select.small:after {
        height: 4px;
        width: 4px; }
      .nice-select.small .option {
        line-height: 34px;
        min-height: 34px; }
    .nice-select .list {
      background-color: #fff;
      border-radius: 5px;
      box-shadow: 0 0 0 1px rgba(68, 68, 68, 0.11);
      box-sizing: border-box;
      margin-top: 4px;
      opacity: 0;
      overflow: hidden;
      padding: 0;
      pointer-events: none;
      position: absolute;
      top: 100%;
      left: 0;
      -webkit-transform-origin: 50% 0;
          -ms-transform-origin: 50% 0;
              transform-origin: 50% 0;
      -webkit-transform: scale(0.75) translateY(-21px);
          -ms-transform: scale(0.75) translateY(-21px);
              transform: scale(0.75) translateY(-21px);
      -webkit-transition: all 0.2s cubic-bezier(0.5, 0, 0, 1.25), opacity 0.15s ease-out;
      transition: all 0.2s cubic-bezier(0.5, 0, 0, 1.25), opacity 0.15s ease-out;
      z-index: 9; }
      .nice-select .list:hover .option:not(:hover) {
        background-color: transparent !important; }
    .nice-select .option {
      cursor: pointer;
      font-weight: 400;
      line-height: 40px;
      list-style: none;
      min-height: 40px;
      outline: none;
      padding-left: 18px;
      padding-right: 29px;
      text-align: left;
      -webkit-transition: all 0.2s;
      transition: all 0.2s; }
      .nice-select .option:hover, .nice-select .option.focus, .nice-select .option.selected.focus {
        background-color: #f6f6f6; }
      .nice-select .option.selected {
        font-weight: bold; }
      .nice-select .option.disabled {
        background-color: transparent;
        color: #999;
        cursor: default; }
  
  .no-csspointerevents .nice-select .list {
    display: none; }
  
  .no-csspointerevents .nice-select.open .list {
    display: block; }
  